import { graphql } from "gatsby";
// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384
import BlogPostPage from "./BlogPostPage";
export default BlogPostPage;

// ALWAYS keep non-static page queries in the index file (Gatsby requirement)!
// Maybe there is a sense to move MarkdownPage component declaration to the index
// file to keep component, props and query together...
// Or to replace this with a StaticQuery
export const jobOpeningQuery = graphql`
  query BlogPostPageQuery(
    $slug: String!
    $author: String!
    $initialLocale: String!
  ) {
    post: markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { locale: { eq: $initialLocale } }
    ) {
      html
      frontmatter {
        mainImage
        title
        category
        date
        keywords
        locale
      }
    }
    author: markdownRemark(frontmatter: { id: { eq: $author } }) {
      frontmatter {
        avatar
        firstName
        lastName
        jobTitle
      }
    }
  }
`;

export { default as BlogPostTemplate } from "./BlogPostTemplate";

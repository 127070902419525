import { Chip, Typography, withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import CloudinaryAvatar from "@src/components/CloudinaryAvatar";
import CmsContent from "@src/components/CmsContent";
import FormattedDate from "@src/components/FormattedDate";
import Link from "@src/components/Link";
import PageLayout from "@src/layouts/PageLayout";

import { ArrowBack } from "@material-ui/icons";
import { styles } from "./BlogPostTemplate.styles";

interface Props extends WithStyles<typeof styles> {
  content: React.ReactNode;
  mainImage: string;
  title: string;
  category: string;
  date: Date;
  author: {
    avatar?: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
  };
}

class BlogPostTemplate extends React.PureComponent<Props> {
  public render() {
    const {
      classes,
      content,
      category,
      mainImage,
      title,
      date,
      author: { avatar, firstName, lastName, jobTitle },
    } = this.props;
    return (
      <PageLayout className={classes.container}>
        <Link to="/blog" className={classes.linkToBlog}>
          <ArrowBack className={classes.iconBack} />
          <Typography variant="subtitle1">
            <T message="Back to Blog" />
          </Typography>
        </Link>
        <img className={classes.mainImage} src={mainImage} alt={title} />
        <Chip color="secondary" label={category} className={classes.category} />

        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={classes.date}>
          <FormattedDate date={date} format="PP" />
        </Typography>

        <div className={classes.author}>
          <CloudinaryAvatar
            src={avatar}
            firstName={firstName}
            lastName={lastName}
            className={classes.authorAvatar}
          />
          <div>
            <Typography variant="subtitle1" className={classes.fullName}>
              {firstName} {lastName}
            </Typography>
            <Typography variant="caption">{jobTitle}</Typography>
          </div>
        </div>

        <CmsContent content={content} className={classes.content} />
      </PageLayout>
    );
  }
}

export default withStyles(styles)(BlogPostTemplate);

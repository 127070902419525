import { Avatar, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import { addTransformationsToUrl } from "@src/utils/cloudinary";

import { styles } from "./CloudinaryAvatar.styles";

export type CloudinaryAvatarProps = {
  className?: string;
  size?: number;
  src?: string;
  firstName?: string;
  lastName?: string;
};

export class CloudinaryAvatar extends React.PureComponent<
  CloudinaryAvatarProps & WithStyles<typeof styles>
> {
  public static defaultProps = {
    size: 64,
  };

  public render() {
    const { className, classes, firstName, lastName, src } = this.props;
    return (
      <Avatar
        className={cn(classes.avatar, className)}
        src={
          src
            ? addTransformationsToUrl(src, {
                crop: "thumb",
                gravity: "face:center",
              })
            : undefined
        }>
        {firstName?.charAt(0)}
        {lastName?.charAt(0)}
      </Avatar>
    );
  }
}

export default withStyles(styles)(CloudinaryAvatar);

import { createStylesWithProps } from "@src/utils/styles";

import { CloudinaryAvatar, CloudinaryAvatarProps } from "./CloudinaryAvatar";

const createStyles = createStylesWithProps<CloudinaryAvatarProps>();

export const styles = () =>
  createStyles({
    avatar: {
      width: (props) => props.size || CloudinaryAvatar.defaultProps.size,
      height: (props) => props.size || CloudinaryAvatar.defaultProps.size,
    },
  });

import memoizeOne from "memoize-one";
import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { MdPageProps } from "@src/types";

import BlogPostTemplate from "./BlogPostTemplate";

export type BlogPostPageProps = MdPageProps<{
  post: {
    html: string;
    frontmatter: {
      body: string;
      mainImage: string;
      title: string;
      category: string;
      date: string;
      keywords: string;
      locale: string;
    };
  };
  author: {
    frontmatter: {
      avatar?: string;
      firstName: string;
      lastName: string;
      jobTitle: string;
    };
  };
}>;

class BlogPostPage extends React.PureComponent<BlogPostPageProps> {
  private extractDescription = memoizeOne((html: string): string => {
    const [description] = `${html} `
      // remove HTML tags
      .replace(/<[^>]+>/g, "")
      // get first 160 chars
      .slice(0, 160)
      // match only whole words
      .match(/(^\s*(?:\S+\s+\n?)+)/gm)!;
    return description;
  });

  private extractKeywords = memoizeOne((keywords: string): string[] => {
    return keywords.split(",").map((kw) => kw.trim());
  });

  public render() {
    const { post, author } = this.props.data;
    const { frontmatter, html } = post;

    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={frontmatter.title}
          description={this.extractDescription(html)}
          keywords={this.extractKeywords(frontmatter.keywords)}
        />
        <BlogPostTemplate
          content={html}
          mainImage={frontmatter.mainImage}
          title={frontmatter.title}
          category={frontmatter.category}
          author={author.frontmatter}
          date={new Date(frontmatter.date)}
        />
      </IndexLayout>
    );
  }
}

export default BlogPostPage;
